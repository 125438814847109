.app__header {
  background-color: var(--color-black);
}

.app__header-h1 {
  font-family: var(--font-base);
  color: var(--color-golden);
  letter-spacing: .04em;

  line-height: 1.3;
  font-size: 90px;
}

.app__header-img img {
  width: 80%;
}

@media screen and (max-width: 1150px) {

}
@media screen and (max-width: 768px) {
  .app__header-h1 {
    letter-spacing: normal;
    line-height: normal;
    font-size: 45px;
  }
}