.app__chef-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5rem 0;
}

.app__chef-content_quote {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.app__chef-content_quote img {
  width: 47px;
  height: 40px;
  margin: 0 1rem 1rem 0;
}

.app__chef-sigh {
  width: 100%;
  margin-top: 3rem;
}

.app__chef-sigh p:first-child {
  font-family: var(--font-base);
  font-weight: 400;
  font-size: 32px;
  line-height: 1.3;
  letter-spacing: 0.04em;
  color: var(--color-golden);
  text-transform: capitalize;
}

.app__chef-sigh img {
  width: 250px;
  margin-top: 3rem;
}



@media screen and (max-width: 2000px) {
  .app__chef-sigh img {
    width: 370px;
  }
}

@media screen and (max-width: 1050px) {

}

@media screen and (max-width: 768px) {
  .app__chef-sigh img {
    width: 80%;
  }

  h2.headtext__cormorant {
    line-height: normal;
    letter-spacing: normal;
  }

}