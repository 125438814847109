.app__specialMenu {
  flex-direction: column;
  background-color: var(--color-black);
}

.app__specialMenu-title {
  margin-bottom: 2rem;
  text-align: center;
}

.app__specialMenu-menu {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.app__specialMenu-menu_heading {
  font-family: "CormorantInfant-SemiBold", serif;
  font-size: 45px;
  line-height: 1.3;
  letter-spacing: .04em;
  color: var(--color-white);
}

.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.app__specialMenu-menu_img {
  width: 410px;
  margin: 0 2rem;
}

.app__specialMenu-menu_img img {
  width: 100%;
  height: auto;
}

.app__specialMenu-menu_items {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  width: 100%;
}




@media screen and (max-width: 2000px) {
  /* .app__specialMenu-menu {
    flex-direction: column;
    align-items: center;
    width: 100%;
  } */
}


@media screen and (max-width: 1050px) {
  .app__specialMenu-menu {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .app__specialMenu-menu_img {
    margin: 2rem  auto;
  }
}


@media screen and (max-width: 768px) {
  .app__specialMenu-menu_img {
    width: 100%;
  }

  .app__specialMenu-menu_heading {
    font-size: 35px;
    line-height: 1.1;
  }
}