@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: "CormorantInfant-Regular";
  src: local("CormorantInfant-Regular"),
    url("./fonts/Cormorant_Infant/CormorantInfant-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "CormorantInfant-Bold";
  src: local("CormorantInfant-Bold"),
    url("./fonts/Cormorant_Infant/CormorantInfant-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "CormorantInfant-Light";
  src: local("CormorantInfant-Light"),
    url("./fonts/Cormorant_Infant/CormorantInfant-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "CormorantInfant-Medium";
  src: local("CormorantInfant-Medium"),
    url("./fonts/Cormorant_Infant/CormorantInfant-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "CormorantInfant-SemiBold";
  src: local("CormorantInfant-SemiBold"),
    url("./fonts/Cormorant_Infant/CormorantInfant-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

:root {
  --font-base: 'Cormorant Infant', serif;
  --font-alt: 'Open Sans', sans-serif;
  
  --color-golden: #DCCA87;
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
}

body {
  background-color: var(--color-black);
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
